import { t } from "i18next";
import { observer } from "mobx-react";
import * as React from "react"
import Layout from "../components/shared-layout";
import useStores from "../hooks/use-stores"

const DashboardPage = () => {
  const { userStore } = useStores();
  return (
    <Layout pageName={t('DASHBOARD')}>
      <div></div>
    </Layout>
  )
}

export default DashboardPage;
